import React, { useState, useRef, useEffect } from 'react';
import '../styles/ws.css';
import '../styles.css';

function Newtester() {

  const [board, setBoard] = useState([]);
  const [wordList, setWordList] = useState([]); // Actual pretty words
  const [rawWordList, setRawWordList] = useState([]) // Words stripped down
  const [lowerCaseWordList, setLowerCaseWordList] = useState([])
  const [foundWords, setFoundWords] = useState([]);
  const [topic, setTopic] = useState("")
  const [highlightedLines, setHighlightedLines] = useState([])
  const [gridSize, setGridSize] = useState(500)
  const [rows, setRows] = useState(0)

  const [isLoading, setIsLoading] = useState(true);

  const [isDrawing, setIsDrawing] = useState(false);
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const [startRow, setStartRow] = useState(0);
  const [startCol, setStartCol] = useState(0);
  let selectedCells = [];
  let currentRow = 0;
  let currentCol = 0;
  const highlightRef = useRef(null);
  const gridRef = useRef(null);

  let lineRemoveTimer

  const [isComplete, setIsComplete] = useState(false)

  let direction = null;
  const directions = ["SN", "NS", "EW", "WE", "NESW", "NWSE", "SENW", "SWNE"];

  const cellSize = gridSize / rows; // Calculate the size of each cell
  const innerCellSize = cellSize * 0.5;
  const highlightSize = cellSize * 0.65;

  useEffect(() => {
    fetch("http://localhost:8081/generate-ws-grid")
    .then(response => response.json())
    .then(data => {
      setRows(data.grid_dimensions)
      setWordList(data.wordList);
      setRawWordList(data.rawWordList)
      setTopic(data.topic);
      setBoard(decompressWS(data.board));
      setHighlightedLines([]);
      setFoundWords([]);
      setIsComplete(false);
    })
    .finally(() => {
      setIsLoading(false)
    })
  }, [])
  // Decompress wordsearch into 2d array
  function decompressWS(compressedString) {
    let rows = compressedString.split(',');
    return rows.map(row => row.split(''));
  }

  function makeLowerCase(list) {
    return list.map(word => word.toLowerCase());
  } 

  // Function to calculate the center of the cell based on the mouse position
  const getCellCenter = (x, y) => {
    const grid = gridRef.current;
    const rect = grid.getBoundingClientRect(); // Get the grid's position relative to the viewport
    const offsetX = x - rect.left; // Mouse X relative to the grid
    const offsetY = y - rect.top;  // Mouse Y relative to the grid

    // Calculate the row and column the mouse is in
    const col = Math.floor(offsetX / cellSize);
    const row = Math.floor(offsetY / cellSize);

    // Calculate the center of the cell
    const centerX = col * cellSize + cellSize / 2;
    const centerY = row * cellSize + cellSize / 2;

    return { centerX, centerY, row, col };
  };

  const isWithinInnerCell = (x, y, row, col) => {
    const grid = gridRef.current;
    const rect = grid.getBoundingClientRect();
    const offsetX = x - rect.left;
    const offsetY = y - rect.top;

    const cellCenterX = col * cellSize + cellSize / 2;
    const cellCenterY = row * cellSize + cellSize / 2;

    const minX = cellCenterX - innerCellSize / 2;
    const maxX = cellCenterX + innerCellSize / 2;
    const minY = cellCenterY - innerCellSize / 2;
    const maxY = cellCenterY + innerCellSize / 2;

    return offsetX >= minX && offsetX <= maxX && offsetY >= minY && offsetY <= maxY;
  }

  const determineDirection = (drow, dcol) => {
    switch (true) {
      // Vertical directions
      case drow < 0 && dcol === 0:
        return 'SN'; // South to North
      case drow > 0 && dcol === 0:
        return 'NS'; // North to South
  
      // Horizontal directions
      case drow === 0 && dcol > 0:
        return 'WE'; // West to East
      case drow === 0 && dcol < 0:
        return 'EW'; // East to West
  
      // Diagonal directions
      case drow === dcol && drow < 0:
        return 'SENW'; // South-East to North-West
      case drow === dcol && drow > 0:
        return 'NWSE'; // North-West to South-East
  
      case drow === -dcol && drow < 0:
        return 'SWNE'; // South-West to North-East
      case drow === -dcol && drow > 0:
        return 'NESW'; // North-East to South-West
  
      // Default case
      default:
        return null;
    }
  };

  const addWord = (newWord) => {
    setFoundWords((prevWords) => [...prevWords, newWord.toLowerCase()]);
  };

  const startLineRemoveTimer = () => {
    lineRemoveTimer = setTimeout(() => {
      const highlight = highlightRef.current;
      highlight.style.opacity = 0;
    }, 200);
  };

  const handleMouseDown = (e) => {
    const { centerX, centerY, row, col } = getCellCenter(e.clientX, e.clientY);
    setIsDrawing(true);
    setStartX(centerX);
    setStartY(centerY);
    setStartRow(row);
    setStartCol(col);

    // Set the initial position of the highlight at the center of the cell
    const highlight = highlightRef.current;

    if (lineRemoveTimer) {
      clearTimeout(lineRemoveTimer); 
      lineRemoveTimer = null;
    }

    highlight.style.opacity = 0.5;

    highlight.style.left = `${centerX + highlightSize / 2}px`;
    highlight.style.top = `${centerY - highlightSize / 2}px`;
    highlight.style.width = '0px';

    console.log(board)
  };

  const handleMouseMove = (e) => {
    if (!isDrawing) return;
    const { centerX, centerY, row, col } = getCellCenter(e.clientX, e.clientY);

    if (row === currentRow && col === currentCol) {
      return;
    }
    if (!isWithinInnerCell(e.clientX,e.clientY, row, col)) {
      return;
    }

    selectedCells = []; 
    currentRow = row;
    currentCol = col;

    const currentDirection = determineDirection(row - startRow, col - startCol);

    if (currentDirection !== direction) {
      direction = currentDirection
    }
    const dx = centerX - startX;
    const dy = centerY - startY;
    const distance = Math.sqrt(dx * dx + dy * dy);
    const angle = Math.atan2(dy, dx) * (180 / Math.PI);

    if (directions.includes(direction) && (row !== startRow || col !== startCol)) {
      const highlight = highlightRef.current;
      highlight.style.width = `${distance + highlightSize}px`;
      highlight.style.transform = `rotate(${angle}deg)`;

      switch (direction) {
        case 'SN':
          console.log('Direction: South to North');
          highlight.style.left = `${startX}px`;
          highlight.style.top = `${startY}px`;
          for (let r = startRow; r >= row; r--) {
            selectedCells.push([r, startCol]);
          }
          break;
        case 'NS':
          console.log('Direction: North to South');
          highlight.style.left = `${startX}px`;
          highlight.style.top = `${startY - highlightSize}px`;
          for (let r = startRow; r <= row; r++) {
            selectedCells.push([r, startCol]);
          }
          break;
        case 'WE':
          console.log('Direction: West to East');
          highlight.style.left = `${startX - highlightSize / 2}px`;
          highlight.style.top = `${startY - highlightSize / 2}px`;
          for (let c = startCol; c <= col; c++) {
            selectedCells.push([startRow, c]);
          }
          break;
        case 'EW':
          console.log('Direction: East to West');
          highlight.style.left = `${startX + highlightSize / 2}px`;
          highlight.style.top = `${startY - highlightSize / 2}px`;
          for (let c = startCol; c >= col; c--) {
            selectedCells.push([startRow, c]);
          }
          break;
        case 'SENW':
          console.log('Direction: South-East to North-West');
          highlight.style.width = `${distance + Math.sqrt(2) * highlightSize}px`;
          highlight.style.left = `${startX + highlightSize * 0.55}px`;
          highlight.style.top = `${startY}px`;
          for (let i = 0; i <= startRow - row; i++) {
            selectedCells.push([startRow - i, startCol - i]);
          }
          break;
        case 'NWSE':
          console.log('Direction: North-West to South-East');
          highlight.style.width = `${distance + Math.sqrt(2) * highlightSize}px`;
          highlight.style.left = `${startX - highlightSize * 0.5}px`;
          highlight.style.top = `${startY - highlightSize}px`;
          for (let i = 0; i <= row - startRow; i++) {
            selectedCells.push([startRow + i, startCol + i]);
          }
          break;
        case 'SWNE':
          console.log('Direction: South-West to North-East');
          highlight.style.width = `${distance + Math.sqrt(2) * highlightSize}px`;
          highlight.style.left = `${startX - highlightSize * 0.55}px`;
          highlight.style.top = `${startY}px`;
          for (let i = 0; i <= col - startCol; i++) {
            selectedCells.push([startRow - i, startCol + i]);
          }
          break;
        case 'NESW':
          console.log('Direction: North-East to South-West');
          highlight.style.width = `${distance + Math.sqrt(2) * highlightSize}px`;
          highlight.style.left = `${startX + highlightSize * 0.5}px`;
          highlight.style.top = `${startY - highlightSize}px`;
          for (let i = 0; i <= startCol - col; i++) {
            selectedCells.push([startRow + i, startCol - i]);
          }
          break;
        default:
          console.log('Unknown direction');
      }
    }

    console.log(selectedCells)  

  };

  const handleMouseUp = (e) => {
    setIsDrawing(false);

    const highlight = highlightRef.current;


    const selectedWord = getSelectedWord(selectedCells);
    const reversedWord = selectedWord.split('').reverse().join('');

    let actualWord;

    if (lowerCaseWordList.includes(selectedWord.toLowerCase())) {
      actualWord = selectedWord;
    } else if (lowerCaseWordList.includes(reversedWord.toLowerCase())) {
      actualWord = reversedWord;
    }

    console.log(selectedWord)

    if (actualWord) {

      if (!foundWords.includes(actualWord.toLowerCase())) {
        addWord(actualWord);

      const currentLine = {
        'word': actualWord.toLowerCase(),
        'width': highlight.style.width,
        'left': highlight.style.left,
        'top': highlight.style.top,
        'transform': highlight.style.transform
      }
      
      setHighlightedLines((prevLines) => [...prevLines, currentLine])
      }
      // reset line after finding valid word
      highlight.style.width = '0px';
    } else {
      startLineRemoveTimer();
    }
    direction = null;
    console.log(rawWordList)
    console.log(wordList)
  };

  const playNewGame = () => {
    fetch("http://localhost:8081/generate-ws-grid")
    .then(response => response.json())
    .then(data => {
      setRows(data.grid_dimensions)
      setWordList(data.wordList);
      setRawWordList(data.rawWordList)
      setTopic(data.topic);
      setBoard(decompressWS(data.board));
      setHighlightedLines([]);
      setFoundWords([]);
      setIsComplete(false);
    })
    .finally(() => {
      setIsLoading(false)
    })
  }

  useEffect(() => {
    setLowerCaseWordList(makeLowerCase(rawWordList))
  }, [rawWordList])

  const getSelectedWord = (cells) => {
    return cells.map(([row, col]) => board[row][col]).join('');
  };

  if (foundWords.length === lowerCaseWordList.length && !isComplete && foundWords.length !== 0) {
    alert('Wordsearch Completed!');
    setIsComplete(true);
  }

  const gridStyle = {
    width: `500px`,
    height: `500px`,
    display: 'grid',
    gridTemplateRows: `repeat(${rows}, ${cellSize}px)`,
    gridTemplateColumns: `repeat(${rows}, ${cellSize}px)`,
    position: 'relative',
    border: '2px solid black',
    cursor: 'pointer'
  };

  const wsGridStyle = {
    display: 'grid',
    gridTemplateRows: `repeat(${rows}, ${cellSize}px)`,
    gridTemplateColumns: `repeat(${rows}, ${cellSize}px)`
  }

  const highlightStyle = {
    height: `${highlightSize}px`,
    borderRadius: `${highlightSize / 2}px`,
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="screen" 
    onMouseUp={handleMouseUp}
    >
      <div className='logo'>
        <span className='puzzle'>Puzzle</span>
        <span className='haven'>Haven</span>
      </div>
      <div className="wsTitle">{topic}</div>
      <div
        ref={gridRef}
        style={gridStyle}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
      >
        <div style={wsGridStyle}>
          {board.map((row, rowIndex) => (
            row.map((letter, colIndex) => (
              <div key={`${rowIndex}-${colIndex}`} className='wsCell'>
                <div className="actualLetter">
                  {letter}
                </div>
              </div>
            ))
          ))}
        </div>
        {highlightedLines.map((line, index) => (
          <div
          key = {index}
          className = 'highlight'
          style={{
          width: line.width,
          height: `${highlightSize}px`,
          borderRadius: `${highlightSize / 2}px`,
          left: line.left,
          top: line.top,
          transform: line.transform
          }}
          />
        ))}
        <div ref={highlightRef} className="highlight" style={highlightStyle}></div>
      </div>
      <div className="remainingWords">
        {wordList.map((word, i) => {
          const isFound = foundWords.includes(rawWordList[i].toLowerCase());
          return (
          <div key={i} className={`wordToFind ${isFound ? 'foundWord' : ''}`}>{word}</div>
        )
        })}
      </div>
      <div className="functionButton" onClick={playNewGame}>Play New Game</div>
    </div>   
  )
}



export default Newtester